<template>
    <div class="blockwarvpn">
        <coming></coming>
    </div>
</template>
<script>
    import {
        defineComponent,
        onMounted,
        ref
    } from "vue";
    import coming from '@/components/coming'
    // import { useStore } from "vuex";

    export default defineComponent({
        name: "blockwarvpn",
        components: {
            coming
        },
        setup() {
            // let store = useStore();
            // let iframeSerc = ref("");
            onMounted(() => {});
            return {};
        },
    });
</script>
<style scoped></style>