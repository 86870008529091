<template>
  <div class="coming_soon">
    <img :src="cdnUrl + '/web_assets/img/coming_soon.png'" alt="" />
    <p>coming online soon...</p>
  </div>
</template>
<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "coming",
  components: {},
  setup() {
    let store = useStore();
    let cdnUrl = computed(() => {
      return store.state.cdnUrl;
    });
    // let iframeSerc = ref("");
    onMounted(() => {});
    return { cdnUrl };
  },
});
</script>
<style scoped lang="scss">
.coming_soon {
  width: 100%;
  text-align: center;
  padding-top: 1.62rem;

  img {
    width: 1.72rem;
    height: auto;
    margin-bottom: 0.27rem;
  }

  p {
    font-size: 0.18rem;
    line-height: 0.22rem;
    color: rgba(255, 255, 255, 0.5);
  }
}
</style>
